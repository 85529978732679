import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import Layout from '../../../components/dfa-theme/layout/hcp'
import BrandCTA from '../../../components/brand-cta'
import { withPrefix } from "gatsby"
import callout from '../../../images/implant_pg_callout.png'
import productShot from '../../../images/SupprelinProductShot_300_layers.png'

const metaTags = {
  description: 'Healthcare professionals can learn about the implant and implantation kit for SUPPRELIN® LA (histrelin acetate) subcuntaneous implant.',
  keywords: 'Homepage',
  title: 'Implantation Kit | SUPPRELIN® LA (histrelin acetate)'
}

// const externalLinksMetaTags = {
//   category: 'External Link',
//   action: 'Click',
//   label: 'Deerfield Homepage',
// }

const reference = (
  <>
    <p><strong>Reference: 1.</strong> SUPPRELIN<sup>&reg;</sup> LA [package insert]. Endo USA, Inc.</p>
  </>
)

const IndexPage = () => (
  <Layout meta={metaTags} hasReferences={true} reference={reference}>
    <Row>
      <Col xs={12}>
        <h1>A full year of consistent medication delivered in a single, small, flexible implant<sup>1</sup></h1>
        <h2>Consistently delivers ~65 mcg of histrelin acetate every day for 1 full year.<sup>1</sup></h2>
      </Col>
    </Row>
    <Row>
      <Col xs={12} md={6}>
          <h2>Minimally invasive procedures<sup>1</sup>:</h2>
          <ul className='brand-list'>
            <li>
              Insertion and removal are minimally invasive surgical procedures that may be performed under local anesthetic using aseptic technique
            </li>
            <li>
              The method of anesthesia used (ie, local, conscious sedation, general) is at the discretion of the healthcare professional
            </li>
            <li>
              For more information on the suggested insertion and removal procedure, please see the full <a className='underline' href='https://www.endodocuments.com/SUPPRELIN-LA/PI' target="blank">Prescribing Information</a>
            </li>
          </ul>
        </Col>
      <Col xs={12} md={6}>
        <img src={callout} alt="Polaroid 1" title="Polaroid 1"/>
      </Col>
    </Row>
    <Row>
      <Col md={12} xs={12}>
        <h1>The SUPPRELIN<sup>&reg;</sup> LA Implantation Kit<sup>1</sup></h1>
        <p>SUPPRELIN<sup>&reg;</sup> LA is supplied in a corrugated shipping carton that contains 2 inner cartons: a small one for the vial containing the SUPPRELIN<sup>&reg;</sup> LA implant, which is shipped with a cold pack inside a polystyrene cooler that must be refrigerated upon arrival, and a larger one comprising the Implantation Kit, which must <em>not</em> be refrigerated, for use during insertion or removal of SUPPRELIN<sup>&reg;</sup> LA. The SUPPRELIN<sup>&reg;</sup> LA Implantation Kit includes all the necessary components for the insertion and removal of the implant (sterile gloves not included). </p>
        <img src={productShot} alt="Implantation Kit" title="Implantation Kit" />
        <p>The Implantation Kit is to be stored at room temperature and should <em>NOT</em> be refrigerated.</p>
      </Col>
    </Row>
    <Row>
      <Col md={12} xs={12}>
      <h2>Components</h2>
      </Col>
    </Row>
      <Row>
      <Col md={6} xs={12}>
      <ul className='brand-list twocolfx'>
        <li> 1 #15 disposable scalpel</li>
        <li> 1 syringe with 18-gauge needle</li>
        <li> 1 25-gauge, 1.5&rdquo; needle</li>
        <li> 1 SS mosquito clamp</li>
        <li> 4 packages gauze sponges</li>
        <li> 2 packages alcohol swabs</li>
        <li> 1 fenestrated drape</li>
        <li> 1 non-fenestrated drape</li>
      </ul>
      </Col>
      <Col md={6} xs={12}>
      <ul className='brand-list'>
        <li> 1 package skin antiseptic swab</li>
        <li> 1 package surgical closure strips</li>
        <li> 1 package coated absorbable suture</li>
        <li> 1 package cohesive bandage</li>
        <li> 1 vial local anesthetic (manufacturer may vary)</li>
        <li> 1 implant insertion tool</li>
        <li> 1 benzoin tincture antiseptic</li>
        <li> Prescribing Information, including Medication Guide*</li>
      </ul>
      </Col>
    </Row>
    <Row>
      <Col xs={12}>
        <p className='footnote asterik'>Item not shown.</p>
      </Col>
    </Row>
    <Row>
      <Col xs={12}>
        <p className='footnote'>
          The SUPPRELIN<sup>&reg;</sup> LA implant contains 50 mg of histrelin acetate. The SUPPRELIN<sup>&reg;</sup> LA
          implant carton contains a cold pack for refrigerated shipment and a small carton containing an amber plastic pouch.
          Inside the pouch is a glass vial with a Teflon-coated stopper and an aluminum seal, containing the implant in 2 mL
          of sterile 1.8% sodium chloride solution. (<strong>Note</strong>: The 3.5-mL vial is not completely filled with saline.)
          <strong> Upon receipt, refrigerate the small carton containing the amber plastic pouch and glass vial (with the implant inside)
          until the day of insertion. The implant vial should not be opened until just before the time of insertion.<sup>1</sup></strong>
        </p>
      </Col>
    </Row>
    <Row between="xs">
      <Col xs={12} md={6}>
        <BrandCTA
          ExternalLink
          href={withPrefix("/pdfs/SP-05716_Surgeon_MVA_DIGITAL_final.pdf")}
          LinkCaption="Download full instructions for implanting and removing the SUPPRELIN<sup>&reg;</sup> LA implant - prodcedure"
        >
          Download full instructions for implanting and removing the SUPPRELIN<sup>&reg;</sup> LA implant
        </BrandCTA>
      </Col>
      <Col xs={12} md={6}>
        <BrandCTA
          href="/hcp/billing-coding/"
          LinkCaption="See suggested billing and coding for SUPPRELIN LA - procedure"
        >
          See suggested billing and coding for <nobr>SUPPRELIN<sup>&reg;</sup> LA<sup>&dagger;</sup></nobr>

        </BrandCTA>
      </Col>
    </Row>
    <Row>
      <Col xs={12}>
          <p className='footnote dagger'><strong>Coding is part of the clinical decision. Please use codes that most accurately reflect the procedures performed. Suggestions by Endo do not guarantee reimbursement or take the place of professional coding advice.</strong> </p>
      </Col>
    </Row>
  </Layout>
)

export default IndexPage;
